// FAQ
const faqTriggers = document.querySelectorAll('.p-faq__list');
faqTriggers.forEach( faqTrigger =>{
    faqTrigger.addEventListener('click', () => {
        faqTrigger.classList.toggle('open');
    })
});

// Fixed
window.addEventListener('DOMContentLoaded', () => {
    // ヘッダーと交差の基準となる要素を定義
    const elmHeader = document.querySelector('.p-fixed');
    const elmIntersect = document.querySelector('.p-mv__subtitle');
    // 交差した時に実行する関数を定義
    // （ヘッダーにカスタム属性を付ける）
    const doWhenIntersect = entries => {
      elmHeader.setAttribute('data-js-scroll', String(!entries[0].isIntersecting));
    }
    // IntersectionObserverをインスタンス化&実行
    const observer = new IntersectionObserver(doWhenIntersect, {});
    observer.observe(elmIntersect);
});